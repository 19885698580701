import { render, staticRenderFns } from "./query-notice1.vue?vue&type=template&id=3069dda1&scoped=true"
import script from "./query-notice1.vue?vue&type=script&lang=js"
export * from "./query-notice1.vue?vue&type=script&lang=js"
import style0 from "./query-notice1.vue?vue&type=style&index=0&id=3069dda1&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3069dda1",
  null
  
)

export default component.exports