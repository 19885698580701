<template>
  <div class="query-notice-container">
    <h2 class="query-notice-title">查询提示</h2>
    <p>根据不动产登记暂时条例实施细则，,第九十七条国家实行不动产登记资料依法查询制度。</p>
    <p>
      权利人、利害关系人按照《条例》第二十七条规定依法查询、复制不动产登记资料的，应当到具体办理不动产登记的不动产登记机构申请。
      权利人可以查询、复制其不动产登记资料。
    </p>
    <p>
      因不动产交易、继承、诉讼等涉及的利害关系人可以查询、复制不动产自然状况、权利人及其不动产查封、抵押、预告登记、异议登记等状况。
      人民法院、人民检察院、国家安全机关、监察机关等可以依法查询、复制与调查和处理事项有关的不动产登记资料。
      其他有关国家机关执行公务依法查询、复制不动产登记资料的，依照本条规定办理。
    </p>
    <p>涉及国家秘密的不动产登记资料的查询，按照保守国家秘密法的有关规定执行。</p>
    <p>第九十八条权利人、利害关系人申请查询、复制不动产登记资料应当提交下列材料：</p>
    <p>（一）查询申请书；</p>
    <p>（二）查询目的的说明；</p>
    <p>（三）申请人的身份材料；</p>
    <p>（四）利害关系人查询的，提交证实存在利害关系的材料。</p>
    <p>
      权利人、利害关系人委托他人代为查询的，还应当提交代理人的身份证明材料、授权委托书。权利人查询其不动产登记资料无需提供查询目的的说明。
      有关国家机关查询的，应当提供本单位出具的协助查询材料、工作人员的工作证。
    </p>
    <p>特别提示：本系统只支持查询注册人名下的房产</p>

    <div class="text-center mt-30">
      <el-button @click="next" type="primary"> 下一步 </el-button>
    </div>
  </div>
</template>

<script>
import { provinceAndCityData, CodeToText } from 'element-china-area-data';
export default {
  name: 'queryNotice',
  data() {
    return {
      systemName: window.CONFIG.APP_INFO.systemName,
      check: false,
      options: provinceAndCityData,
      selectedOptions: [],
      sqrqydm: '',
      sqrip: '',
      sqrszd: '',
    };
  },
  methods: {
    next() {
      this.$emit('next', 1);
    },
    changeBox(val) {
      // if(val){
      //   this.timeCount = 0;
      // }
    },
  },

  mounted() {
    this.showCityInfo();
  },
  computed: {
    disabled() {
      return !this.check;
    },
  },
};
</script>

<style lang="scss" scoped>
.mt-30 {
  margin-top: 30px;
}

/deep/ .el-checkbox__label {
  display: inline;
}

.el-checkbox,
.el-checkbox__input {
  display: inline;
}

.query-notice-container {
  margin: 20px 0;
  padding: 20px 140px;
  // background-color: #FFFFFF;
  text-indent: 25px;
  font-size: 13px;

  .query-notice-title {
    background-color: #f6f8fb;
    height: 74px;
    line-height: 74px;
    text-align: center;
    font-size: 18px;
    color: #101010;
    font-family: SourceHanSansSC-regular;
  }
}

h2 {
  margin: 20px 0;
}

p {
  line-height: 35px;
  font-size: 16px;
}

.text-red {
  color: red;
}
</style>
